import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ChevronRight,
  ChevronDown,
  CheckCircle,
  Home,
  TimerIcon,
  FileText,
  HelpCircle
} from 'lucide-react';

const GBISPage = () => {
  const [activeFaq, setActiveFaq] = useState(null);

  return (
    <div className="overflow-x-hidden">
      {/* Hero Banner */}
      <section className="w-full mt-32 px-4 py-16 bg-[#2c983f]">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-white">Great British Insulation Scheme</h1>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <div className="prose max-w-none">
            <p className="text-xl mb-6">Various Grants are being provided to UK households under the Great British Insulation Scheme.</p>
            
            <h2 className="text-3xl font-bold text-green-600 mb-6">Everything You Need to Know about the Great British Insulation Scheme (GBIS)</h2>
            
            <p className="mb-4">Formerly known as Energy Company Obligation+ (ECO+), the Great British Insulation Scheme is a Government program of the UK to help their Citizens Reduce their energy bills.</p>
            
            <p className="mb-4">It is linked to the Energy Company Obligation Scheme, but instead of Focusing on Whole-House Improvements, it concentrates on Individual Insulation measures.</p>
            
            <p className="mb-8">This is the First Scheme introduced by the new Department of Energy Security and Net Zero, and is Administered by Ofgem.</p>
          </div>
        </div>
      </section>

      {/* What is GBIS Section */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 mb-6">What is GBIS?</h2>
          <div className="space-y-4">
            <p>The UK Government, committed to achieving Net Zero Carbon Emissions by 2050, has Legislated several Energy Efficiency Grant Schemes.</p>
            <p>The Great British Insulation Scheme is one of them!</p>
            <p>It is specifically designed to enhance Energy Efficiency and will concentrate on Installing Insulation measures such as Loft and Cavity Wall Insulation.</p>
            <p>The scheme is Estimated to improve the Energy Performance of Approximately 300,000 Homes, which as a Result will cause Average Annual Energy Bill savings of £300-400.</p>
          </div>
        </div>
      </section>

      {/* Funding Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 mb-6">What is the Maximum Funding Available through GBIS?</h2>
          <div className="space-y-4">
            <p>The Total Funding Pool for GBIS is £1 billion, and the level of funding for each Applicant depends upon the type of Insulation measure implemented.</p>
            <p>Moreover, if you are from a "Low-Income Group" then the Installation process is completely Free for you.</p>
            <p>However, if you fall in the General Group Category You can still receive Funds for Installation of Insulation (you will be Contributing towards the Total Final Cost).</p>
            <p>The Installer will submit an application on your Behalf forFunding and Subsequently Claim Funds Directly from the Energy Company.</p>
          </div>
        </div>
      </section>

      {/* Eligibility Section */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 mb-6">Who Qualifies for the GBIS?</h2>
          <p className="mb-4">The Great British Insulation Scheme follows Two Eligibility Pathways.</p>
          
          <div className="flex gap-4 mb-4">
            <p>General</p>
            <p>Low-Income</p>
          </div>

          <p className="mb-4">Low-Income Households, meeting specific benefit and Income Criteria, are Eligible for the Low-Income Stream.</p>
          <p className="mb-6">All the other Households fall into the category of General Criteria.</p>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Low Income Group */}
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <h3 className="text-xl font-bold mb-4">LOW-INCOME GROUP</h3>
              <p className="mb-4">To fall under this group you must:</p>
              <ul className="list-none space-y-2">
                <li className="flex items-start gap-2">
                  <CheckCircle className="w-5 h-5 text-green-600 mt-1 flex-shrink-0" />
                  <span>Receive Qualifying Benefits</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="w-5 h-5 text-green-600 mt-1 flex-shrink-0" />
                  <span>Have an EPC Rating of D to G</span>
                </li>
              </ul>
              <p className="mt-4">If you are Eligible in this category, then you will be receiving an Insulation upgrade Free of cost. And owner occupiers may also qualify for Heating Controls.</p>
            </div>

            {/* General Group */}
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <h3 className="text-xl font-bold mb-4">GENERAL GROUP</h3>
              <p className="mb-4">To fall under this group you must:</p>
              <ul className="list-none space-y-2">
                <li className="flex items-start gap-2">
                  <CheckCircle className="w-5 h-5 text-green-600 mt-1 flex-shrink-0" />
                  <span>Living in a House in Council Tax Bands A to D (England)</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="w-5 h-5 text-green-600 mt-1 flex-shrink-0" />
                  <span>Living in a House in Council Tax Bands A to E (Wales and Scotland)</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="w-5 h-5 text-green-600 mt-1 flex-shrink-0" />
                  <span>EPC rating D to G</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="w-5 h-5 text-green-600 mt-1 flex-shrink-0" />
                  <span>Living in a Rented Home must Possess EpC with D or E ratings</span>
                </li>
              </ul>
              <p className="mt-4">If you are eligible in the General category, then you will receive grants but with the self Contribution of 10% of the TotalI Insulation Installation Cost.</p>
            </div>
          </div>

          <p className="mt-6">Your EPC Rating determines your Eligibility for a Great British Insulation Scheme Grant.</p>
          <p className="mt-4">Check your EPC rating and Grant Eligibility using the Free Checker on Eco Solar Shine.</p>
        </div>
      </section>
      {/* How to Apply Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 mb-6">How to Apply for GBIS?</h2>
          <p className="mb-6">Go through the following Step-By-Step Guide to Apply for the Great British Insulation Scheme:</p>
          
          <div className="space-y-6">
            <div className="bg-gray-50 p-4 rounded">
              <p>Apply for the Eligibility Checker online (information about your house or property will be needed to process this step)</p>
            </div>
            
            <div className="bg-gray-50 p-4 rounded">
              <p>Your Application will then move to the Assessment Process, and if found eligible then a free survey will be scheduled by a Registered Installer to your Property.</p>
            </div>
            
            <div className="bg-gray-50 p-4 rounded">
              <p>After the Assessment Completion, Insulation options will be provided as per your needs and requirements, including any Installation Cost (if applicable).</p>
            </div>
            
            <div className="bg-gray-50 p-4 rounded">
              <p>Upon your Agreement, the Installation date will be decided. You will need to pay any amount (if applicable) to the Registered Installer before Installation.</p>
            </div>
            
            <div className="bg-gray-50 p-4 rounded">
              <p>The Installer completes the Installation and claims the Funding directly.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Waiting Time Section */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mb-4 mx-auto">
          <h2 className="text-3xl font-bold text-green-600 mb-6">What Is The Waiting Time For The Installation Of Free Grants Under Gbis?</h2>
          <p>Insulation Installation typically takes 2-4 weeks. However, the specific timeline can vary based on factors like Insulation Type, Grant Application Processing Time, Installer Availability, and Funding Levels.</p>
        </div>
      <img className='max-w-2xl mx-auto' src="/assets/lads1.jpg" />

      </section>

      

      {/* Available Grants Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 mb-6">What Types Of Grants Are Available Under Gbis?</h2>
          <p className="mb-4">The Great British Insulation Scheme aims to improve Home Energy Efficiency by offering Grants for various Insulation Measures.</p>
          <p className="mb-6">Theoretically, the Scheme covers a wide range of Insulation options such as:</p>
          <img className='max-w-2xl mx-auto' src="/assets/lads2.jpg" />
          
          <p className="mt-6">Currently, the two most Funded Grants are Loft and Cavity Wall Insulation.</p>
        </div>
      </section>

      {/* Payment Section */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 mb-6">Is There A Need For Any Payment Before Installation?</h2>
          <p className="mb-4">Although it's a Fully Funded Scheme, with some Exceptions.</p>
          <p className="mb-4">For example, you will be asked for Payments toward Installation if:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>You opt for a more Expensive Insulation Measure (some are more expensive than others)</li>
            <li>You qualify for the General Category (the Low-Income Category receives more funding)</li>
          </ul>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 mb-6">GBIS FAQs</h2>
          
          <div className="space-y-4">
            {[
              {
                q: "Does a Private Rented Property get benefits from the Scheme?",
                a: "Yes, both Private and Social Housing Properties can apply. However, Landlord Permission is required, and Tenants must meet specific Eligibility Criteria, such as Low Income or Low EPC Rating."
              },
              {
                q: "How to check your EPC Rating?",
                a: "Check your property's EPC Rating on the UK Government EPC Register or our Free Grant Checker. If it's missing, your Installer will arrange an Assessment. Avoid getting a new EPC while your application is in progress."
              },
              {
                q: "How to check the Council Tax Band?",
                a: "You can check on the government website by entering your Postal Code."
              },
              {
                q: "How to apply for GBIS?",
                a: "Find a Trustmark Register Installer or use our Free Grant Checker to apply."
              }
            ].map((faq, index) => (
              <div key={index} className="bg-green-600 rounded-lg overflow-hidden">
                <button
                  className="w-full px-6 py-4 text-left text-white flex items-center justify-between font-semibold"
                  onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                >
                  <span>{faq.q}</span>
                  {activeFaq === index ? (
                    <ChevronDown className="w-5 h-5" />
                  ) : (
                    <ChevronRight className="w-5 h-5" />
                  )}
                </button>
                {activeFaq === index && (
                  <div className="px-6 py-4 bg-gray-50">
                    <p>{faq.a}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full px-4 py-16 bg-[#2c983f] text-white">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">How to apply for GBIS?</h2>
          <p className="text-xl mb-8">Find a Trustmark Register Installer or use our Free Grant Checker to apply.</p>
          <Link to='/check' className="inline-block px-8 py-4 bg-white text-[#2c983f] rounded-lg text-lg font-semibold hover:bg-gray-100 transition-all">
            Check Eligibility Now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default GBISPage;