// Helper function to check if the EPC rating is eligible
const isEligibleEPCRating = (epcRating, scheme) => {
  if (!epcRating) return false; // Return false if epcRating is undefined or null
  if (scheme === 'ECO4' || scheme === 'LADS') {
      return ['D', 'E', 'F', 'G'].includes(epcRating.toUpperCase());
  }
  return false;
};

// Helper function to check if the household income is eligible for LADS
const isEligibleIncome = (income) => {
  return income <= 31000;
};

// Helper function to check if the benefit is eligible for ECO4
const isEligibleBenefit = (benefits) => {
  const eligibleBenefits = [
    'Income-based Jobseeker\'s Allowance',
    'Income-related Employment and Support Allowance',
    'Income Support',
    'Pension Credit (Guarantee Credit)',
    'Child Tax Credit',
    'Working Tax Credit',
    'Universal Credit',
    'Housing Benefit',
    'Pension Credit (Savings Credit)',
    'Child Benefit'
  ];
  return benefits.some(benefit => eligibleBenefits.includes(benefit));
};

export const checkECO4Eligibility = (formData, certificate) => {
  const epcRating = certificate?.['current-energy-rating'];
  const isBenefitRecipient = Array.isArray(formData.benefits) && formData.benefits.length > 0 && isEligibleBenefit(formData.benefits);

  let eligibilityReasons = [];
  let isEligible = false;

  if (!certificate) {
    eligibilityReasons.push("Manual address provided; EPC rating could not be verified.");
  } else if (isBenefitRecipient && isEligibleEPCRating(epcRating, 'ECO4')) {
    isEligible = true;
    eligibilityReasons.push("Household receives eligible benefits and property has eligible EPC rating.");
  }

  if (formData.relationship === 'housingAssociation' && (!certificate || ['E', 'F', 'G'].includes(epcRating?.toUpperCase()))) {
    isEligible = true;
    eligibilityReasons.push("Social housing tenant with EPC rating of E, F, or G, or manual address provided.");
  }

  if (formData.heatingType === 'noHeating' || formData.heatingAge === '16+') {
    isEligible = true;
    eligibilityReasons.push("Property has no heating system or an old heating system.");
  }

  if (formData.wallType.includes('Uninsulated') || formData.roofInsulation.includes('Uninsulated')) {
    isEligible = true;
    eligibilityReasons.push("Property has uninsulated walls or roof.");
  }

  return { isEligible, eligibilityReasons };
};
export const checkLADSEligibility = (formData, certificate) => {
  const epcRating = certificate?.['current-energy-rating'];
  const isEligibleHouseholdIncome = isEligibleIncome(formData.householdIncome);

  let eligibilityReasons = [];
  let isEligible = false;

  if (!certificate) {
    eligibilityReasons.push("Manual address provided; EPC rating could not be verified.");
  } else if (isEligibleEPCRating(epcRating, 'LADS') && isEligibleHouseholdIncome) {
    isEligible = true;
    eligibilityReasons.push("Property has eligible EPC rating and household income is below threshold.");
  }

  if (['homeowner', 'privateTenant'].includes(formData.relationship)) {
    eligibilityReasons.push("Eligible property type (owner-occupied or privately rented).");
  } else if (formData.relationship === 'housingAssociation' && (!certificate || ['E', 'F', 'G'].includes(epcRating?.toUpperCase()))) {
    isEligible = true;
    eligibilityReasons.push("Social housing tenant with EPC rating of E, F, or G, or manual address provided.");
  }

  if (formData.heatingType === 'noHeating' || formData.heatingAge === '16+') {
    isEligible = true;
    eligibilityReasons.push("Property has no heating system or an old heating system.");
  }

  if (formData.wallType.includes('Uninsulated') || formData.roofInsulation.includes('Uninsulated')) {
    isEligible = true;
    eligibilityReasons.push("Property has uninsulated walls or roof.");
  }

  return { isEligible, eligibilityReasons };
};

export const getEligibleGrants = (formData, certificate) => {
  const eco4Eligibility = checkECO4Eligibility(formData, certificate);
  const ladsEligibility = checkLADSEligibility(formData, certificate);

  let eligibleGrants = [];

  if (eco4Eligibility.isEligible) {
    eligibleGrants.push({
      name: "ECO4",
      reasons: eco4Eligibility.eligibilityReasons
    });
  }

  if (ladsEligibility.isEligible) {
    eligibleGrants.push({
      name: "LADS",
      reasons: ladsEligibility.eligibilityReasons
    });
  }

  return eligibleGrants;
};

export const getRecommendations = (formData, certificate) => {
  let recommendations = [];

  if (formData.heatingType === 'noHeating' || formData.heatingAge === '16+') {
    recommendations.push("Consider upgrading to a more efficient heating system");
  }

  if (formData.wallType.includes('Uninsulated')) {
    recommendations.push("Look into wall insulation options");
  }

  if (formData.roofInsulation.includes('Uninsulated')) {
    recommendations.push("Consider improving roof insulation");
  }

  if (certificate?.['current-energy-rating'] && certificate['current-energy-rating'] > 'C') {
    recommendations.push("Explore ways to improve your property's energy efficiency rating");
}

  return recommendations;
};