import { Helmet } from 'react-helmet-async';

const PageTitle = ({ title }) => {
  const websiteName = process.env.REACT_APP_WEBSITE_NAME || 'EcoShine Solar';
  return (
    <Helmet>
      <title>{title} | {websiteName}</title>
    </Helmet>
  );
};

export default PageTitle;