import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Save, Loader, AlertTriangle, ArrowLeft,Upload } from 'lucide-react';
import config from '../config';

const BlogEditor = () => {
  const [blog, setBlog] = useState({ title: '', body: '', author: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  useEffect(() => {
    if (id) {
      const fetchBlog = async () => {
        try {
          const token = localStorage.getItem('adminToken');
          const response = await axios.get(`${config.apiUrl}/api/blogs/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setBlog(response.data);
          if (response.data.featuredImage) {
            setPreviewImage(`${config.uploadPath}/${response.data.featuredImage}`);
          }
        } catch (error) {
          setError('Failed to fetch blog. Please try again.');
        }
      };
      fetchBlog();
    }
  }, [id]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      // Create preview URL for the selected file
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('adminToken');
      const formData = new FormData();
      formData.append('title', blog.title);
      formData.append('body', blog.body);
      formData.append('author', blog.author);
      
      if (selectedFile) {
        formData.append('featuredImage', selectedFile);
      }

      if (id) {
        await axios.put(`${config.apiUrl}/api/blogs/${id}`, formData, {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        await axios.post(`${config.apiUrl}/api/blogs`, formData, {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      navigate('/admin/blogs');
    } catch (error) {
      setError('Failed to save blog. Please try again.');
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  };

  const handleBodyChange = (content) => {
    setBlog({ ...blog, body: content });
  };

  // Cleanup preview URL when component unmounts or new file is selected
  useEffect(() => {
    return () => {
      if (previewImage && !previewImage.startsWith('http')) {
        URL.revokeObjectURL(previewImage);
      }
    };
  }, [previewImage]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 via-emerald-50 to-teal-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden">
          {/* Header section remains the same */}
          <div className="p-6 bg-emerald-600 text-white flex justify-between items-center">
            <h1 className="text-3xl font-bold">{id ? 'Edit Blog' : 'Create New Blog'}</h1>
            <button
              onClick={() => navigate('/admin/blogs')}
              className="flex items-center text-white hover:text-emerald-100 transition-colors px-4 py-2 rounded-full bg-emerald-700/30 hover:bg-emerald-700/50"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Blogs
            </button>
          </div>

          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 m-6 rounded-r-lg">
              <div className="flex items-center">
                <AlertTriangle className="w-6 h-6 text-red-400 mr-2" />
                <p className="text-red-700">{error}</p>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-emerald-700">Blog Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={blog.title}
                  onChange={handleChange}
                  placeholder="Enter blog title"
                  required
                  className="mt-1 block w-full border border-emerald-200 rounded-lg shadow-sm py-3 px-4 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 bg-white/50"
                />
              </div>

              <div>
                <label htmlFor="author" className="block text-sm font-medium text-emerald-700">Author</label>
                <input
                  type="text"
                  id="author"
                  name="author"
                  value={blog.author}
                  onChange={handleChange}
                  placeholder="Enter author name"
                  required
                  className="mt-1 block w-full border border-emerald-200 rounded-lg shadow-sm py-3 px-4 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 bg-white/50"
                />
              </div>
            </div>

            <div>
              <label htmlFor="featuredImage" className="block text-sm font-medium text-emerald-700">Featured Image</label>
              <div className="mt-1 flex flex-col space-y-4">
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-emerald-200 border-dashed rounded-lg cursor-pointer bg-emerald-50 hover:bg-emerald-100 transition-colors">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <Upload className="w-8 h-8 mb-3 text-emerald-500" />
                      <p className="mb-2 text-sm text-emerald-600">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                      </p>
                      <p className="text-xs text-emerald-500">PNG, JPG or JPEG (MAX. 5MB)</p>
                    </div>
                    <input
                      type="file"
                      id="featuredImage"
                      accept="image/*"
                      className="hidden"
                      onChange={handleFileSelect}
                    />
                  </label>
                </div>
                
                {previewImage && (
                  <div className="relative rounded-lg overflow-hidden shadow-lg h-48">
                    <img
                      src={previewImage}
                      alt="Preview"
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        e.target.onerror = null;
                        setPreviewImage('');
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedFile(null);
                        setPreviewImage('');
                      }}
                      className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                    >
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="body" className="block text-sm font-medium text-emerald-700 mb-2">Blog Content</label>
              <div className="prose-container rounded-lg overflow-hidden shadow-lg">
                <ReactQuill
                  value={blog.body}
                  onChange={handleBodyChange}
                  modules={modules}
                  className="bg-white rounded-lg"
                />
              </div>
            </div>

            <div className="flex justify-end pt-6">
              <button
                type="submit"
                disabled={loading}
                className="flex items-center justify-center py-3 px-6 rounded-full text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-300 shadow-lg hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <Loader className="w-5 h-5 mr-2 animate-spin" />
                ) : (
                  <Save className="w-5 h-5 mr-2" />
                )}
                {loading ? 'Saving...' : 'Save Blog'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlogEditor;