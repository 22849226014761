import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import GlobalImageLoader from './components/GlobalImageLoader';
import CheckEligibilityPage from './components/CheckEligibilityPage';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import AdminLayout from './components/AdminLayout';

import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import BlogManagement from './components/BlogManagement';
import BlogEditor from './components/BlogEditor';

import GrantList from './components/GrantList';

import Header from './components/Header';
import Footer from './components/Footer';
import CheckEligibilityPage2 from './components/CheckEligibilityPage2';
import EcoGrantsPage from './components/EcoGrantsPage';
import FAQPage from './components/FAQPage';
import ContactUsPage from './components/ContactUsPage';
import GBISPage from './components/GBISPage';
import LADSPage from './components/Lads';
import ScrollToTop from './components/ScrollToTop';
import PageTitle from './components/PageTitle';
import NotFound from './components/NotFound';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop>
          <GlobalImageLoader>
            <div className="App">
              <Header/>
              <Routes>
                <Route path="/" element={<><PageTitle title="Home" /><CheckEligibilityPage /></>} />
                <Route path="/blogs" element={<><PageTitle title="Blogs" /><BlogList /></>} />
                <Route path="/check" element={<><PageTitle title="Check Eligibility" /><CheckEligibilityPage2 /></>} />
                <Route path="/blog/:id" element={<BlogPost />} />
                <Route path="/grants" element={<><PageTitle title="Grants" /><GrantList /></>} />
                <Route path="/faq" element={<><PageTitle title="FAQ" /><FAQPage /></>} />
                <Route path="/contact" element={<><PageTitle title="Contact Us" /><ContactUsPage /></>} />
                <Route path="/admin/" element={<><PageTitle title="Admin Login" /><AdminLogin /></>} />
                <Route path="/grants/eco4" element={<EcoGrantsPage />} />
                <Route path="/grants/gbis" element={<GBISPage />} />
                <Route path="/grants/lads" element={<LADSPage />} />
                <Route 
                  path="/admin/*" 
                  element={
                    <AdminLayout>
                      <Routes>
                        <Route path="dashboard" element={<AdminDashboard />} />
                        <Route path="blogs" element={<BlogManagement />} />
                        <Route path="blogs/new" element={<BlogEditor />} />
                        <Route path="blogs/edit/:id" element={<BlogEditor />} />
                      </Routes>
                    </AdminLayout>
                  } 
                />
                <Route path="*" element={<><PageTitle title="404 - Not Found" /><NotFound /></>} />
              </Routes>
              <Footer/>  
            </div>
          </GlobalImageLoader>
        </ScrollToTop>
      </Router>
    </HelmetProvider>
  );
}

export default App;