import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  CheckCircle,
  ThermometerSun,
  Zap,
  Home,
  ChevronRight,
  ChevronDown,
  HelpCircle
} from 'lucide-react';

const EcoGrantsPage = () => {
 // Set the target date and time (in milliseconds)
 const targetDate = new Date("2026-03-31T00:00:00").getTime();

 const calculateTimeLeft = () => {
   const now = new Date().getTime();
   const difference = targetDate - now;

   let timeLeft = {};
   if (difference > 0) {
     timeLeft = {
       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
       minutes: Math.floor((difference / 1000 / 60) % 60),
       seconds: Math.floor((difference / 1000) % 60),
     };
   } else {
     timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
   }

   return timeLeft;
 };

 const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

 useEffect(() => {
   const timer = setInterval(() => {
     setTimeLeft(calculateTimeLeft());
   }, 1000);

   // Clear interval on component unmount
   return () => clearInterval(timer);
 }, []);

  const [activeFaq, setActiveFaq] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev.seconds > 0) {
          return { ...prev, seconds: prev.seconds - 1 };
        } else if (prev.minutes > 0) {
          return { ...prev, minutes: prev.minutes - 1, seconds: 59 };
        } else if (prev.hours > 0) {
          return { ...prev, hours: prev.hours - 1, minutes: 59, seconds: 59 };
        } else if (prev.days > 0) {
          return { ...prev, days: prev.days - 1, hours: 23, minutes: 59, seconds: 59 };
        }
        return prev;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="overflow-x-hidden">

<section className="w-full mt-32 px-4 py-16 bg-[#2c983f]">
  <div className='text-center'>
<h1 className="text-4xl font-bold text-white">Government's Energy Company Obligation (ECO) | EcoShine Solar</h1>
</div>
  </section>

      {/* Breadcrumb Banner */}
      <div className="bg-gray-100 py-3 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center justify-center gap-2 text-sm">
            <Link to="/" className="text-[#2c983f] hover:text-[#82cc2b]">Home</Link>
            <ChevronRight className="w-4 h-4" />
            <Link to="/grants" className="text-[#2c983f] hover:text-[#82cc2b]">Grants</Link>
            <ChevronRight className="w-4 h-4" />
            <span className="text-gray-600">ECO4</span>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <section className="w-full px-4 py-16 bg-gradient-to-r from-white">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row gap-12 items-center">
            <div className="w-full lg:w-1/2 space-y-6">
              <h2 className="text-3xl text-green-600 font-bold">Energy-efficiency grants</h2>
              <p className="text-xl">Our program offers Grants to people who struggle with High Energy Bills as compared to their Household Incomes.</p>
              <p className="text-xl">We are accepting applications to provide you with Suitable Grants that will help you in Reducing your Energy Bills.</p>
            </div>
            <div className="w-full lg:w-1/2">
              <img 
                src="/assets/solar1.jpg" 
                alt="Energy efficiency" 
                className="rounded-xl shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 text-center mb-8">Why choose us?</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              'Completely Free Services',
              'Government-Funded Energy Upgrades',
              'No Commitments',
              'Instant Eligibility Check'
            ].map((benefit, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg flex items-center gap-3">
                <CheckCircle className="w-5 h-5 text-[#2c983f]" />
                <span className="font-medium">{benefit}</span>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <Link
              to="/check"
              className="inline-block px-8 py-4 bg-[#2c983f] text-white rounded-lg text-lg font-semibold hover:bg-[#82cc2b] transition-all"
            >
              CHECK ELIGIBILITY NOW
            </Link>
          </div>
        </div>
      </section>

      {/* Available Grants */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-green-600 mb-4">Grants</h2>
          <p className="text-xl text-center mb-12">Entitle yourself to the Benefits you deserve</p>
          <p className="text-center mb-12">Check out these available Grants for heating and insulation and claim now.</p>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 justify-around">
            {[
              {
                icon: ThermometerSun,
                title: 'Boiler Upgrade or Replacement',
                desc: 'Homeowners with Non-Condensing Boilers can upgrade their Boilers via this Scheme.'
              },
              {
                icon: Zap,
                title: 'Electric Heating Upgrade',
                desc: 'Especially for the Households using Electricity for Heating.'
              },
              {
                icon: Home,
                title: 'First Time Central Heating',
                desc: 'Properties that never had Central Heating ever, can get it done under this Scheme.'
              },
              {
                icon: Home,
                title: 'Insulation',
                desc: 'Cavity Wall Insulation, Underfloor Insulation, Loft Insulation, Solid Wall Insulation, and Room-In Insulation, all these Insulations are available under LAD Scheme.'
              }
            ].map((grant, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                  <grant.icon className="h-6 w-6 text-[#2c983f]" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{grant.title}</h3>
                <p className="text-gray-600 mb-4">{grant.desc}</p>
                <Link
                  to="/check"
                  className="text-[#2c983f] font-semibold hover:text-[#82cc2b] flex items-center"
                >
                  Check Eligibility <ChevronRight className="ml-1 w-4 h-4" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-green-600 mb-4">Process</h2>
          <p className="text-center mb-12">Simple and easy application process</p>
          <p className="text-center mb-12">Check your Eligibility with our Free Grant Checker</p>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: 'Apply',
                desc: 'Simple and easy process only takes a minute or 2'
              },
              {
                title: 'Survey',
                desc: 'Free survey of the property, once found eligible'
              },
              {
                title: 'Installation',
                desc: 'Installation scheduled at a time suitable for Registered Installers'
              }
            ].map((step, index) => (
              <div key={index} className="text-center">
                <div className="w-16 h-16 bg-[#2c983f] rounded-full flex items-center justify-center mx-auto mb-6 text-white text-2xl font-bold">
                  {index + 1}
                </div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4 text-green-600">FAQs</h2>
            <p className="mb-4">Feel free to contact us for any questions or queries. We are always happy to help you in every way possible.</p>
          </div>

          <div className="space-y-4s">
            {[
              {
                q: 'Do I qualify for the ECO4 Scheme?',
                a: 'Anyone having high fuel costs in accordance with their income qualifies for the Scheme. This Scheme is for private renters, homeowners, and tenants who are recipients of at least one qualifying benefit.'
              },
              {
                q: 'How much will one get from the Scheme?',
                a: 'It all depends on the free survey that will take place after one is eligible for the Scheme. However, the Grant is directly paid to the Installer.'
              },
              {
                q: 'How long is the application process for this Grant?',
                a: 'It usually takes around 4 weeks or less from the time you apply for the Application to the Installation of the Grant.'
              }
            ].map((faq, index) => (
              <div key={index} className="bg-green-600 rounded-lg overflow-hidden my-1">
                <button
                  className="w-full px-6 py-4 text-left text-white flex items-center justify-between font-semibold"
                  onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                >
                  <span>{faq.q}</span>
                  {activeFaq === index ? (
                    <ChevronDown className="w-5 h-5" />
                  ) : (
                    <ChevronRight className="w-5 h-5" />
                  )}
                </button>
                {activeFaq === index && (
                  <div className="px-6 py-4 bg-gray-50">
                    <p>{faq.a}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="w-full px-4 py-16 bg-green-600">
        <div className="max-w-6xl mx-auto text-center text-white">
          <h2 className="text-4xl font-bold mb-4">Energy Efficient Grants Installed</h2>
          <div className="text-6xl font-bold mb-4">2,791,372</div>
          <p className="text-lg">
            This is the number of energy-efficient Grants installed under the ECO4 scheme as mentioned by Ofgem in their statistics of "Promoting a Sustainable Energy Future" published on 27th August 2020.
          </p>
        </div>
      </section>

      {/* Funding Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6 text-green-600">Funded by well-known Energy Companies</h2>
          <p className="mb-8">Energy companies are legally required to fund the Energy Company Obligation Scheme.</p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
  {/* Placeholder for energy company logos */}
  {[1, 2, 3, 4].map((item) => (
    <div key={item} className="bg-gray-100 h-20 rounded-lg flex items-center justify-center">
      <img src={`/assets/${item}.png`} alt={`Energy company ${item}`} className="h-26" />
    </div>
  ))}
</div>
        </div>
      </section>

      {/* Registration Section */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-6 text-green-600">Registration and warranties</h2>
            <p className="mb-8">
              All work carried out under the Energy Company Obligation (ECO) scheme adheres to the highest standards. Installers must be registered with TrustMark before performing any work under the scheme.
              Many are also certified by additional organizations, depending on the type of measures being installed.
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {/* Placeholder for installer certification logos */}
            {['a','b','c','d','e','f','g','h'].map((item) => (
              <div key={item} className="bg-white p-4 rounded-lg shadow flex items-center justify-center">
              <img src={`/assets/${item}.png`} alt={`Energy company ${item}`} className="h-26" />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="w-full px-10 py-10 bg-[#2c983f] text-white">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">
          Who can Apply?
          </h2>
          <p className="text-xl">The scheme is designed to assist individuals experiencing high fuel costs and low income. Eligibility for grants may be determined by factors such as receipt of government benefits, or certification as fuel poor or low-income and vulnerable by the local authority. Even if you do not claim benefits, you may still qualify based on local authority funding criteria.</p>
          </div>
          </section>
            {/* Benefits Section */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center text-green-600">Qualifying Benefits</h2>
          <p className="text-xl text-center mb-8">If you receive one of these benefits (except Child Benefit), you're automatically eligible, regardless of income.</p>
          <div className="space-y-4s">
            {[
{
  q: 'Child benefits',
  a: 'If Child Benefit is the sole qualifying benefit received, eligibility will be determined by additional income criteria, as outlined here.'
},
{
  q: 'Child tax credit',
  a: 'Individuals in receipt of Child Tax Credit are exempt from income testing for the Energy Company Obligation Scheme.'
},
{
  q: 'Income support',
  a: 'Individuals in receipt of Income Support are exempt from income testing for the Energy Company Obligation Scheme.'
},
{
  q: 'Pension Credit',
  a: 'Individuals in receipt of Pension Credit are exempt from income testing for the ECO Scheme.'
},
{
  q: 'Employment and support allowance',
  a: 'Individuals in receipt of Employment and Support Allowance are exempt from income testing for the ECO Scheme.'
},
{
  q: 'Job-seekers allowance',
  a: 'Individuals in receipt of Job-seekers Allowance are exempted from income testing for the ECO Scheme.'
},
{
  q: 'Universal Credit',
  a: 'Individuals receiving Universal Credit are exempted from income testing for the ECO Scheme.'
},
{
  q: 'Working tax credit',
  a: 'Individuals in receipt of Working Tax Credit are exempted from income testing for the ECO Scheme.'
}
            ].map((faq, index) => (
              <div key={index} className="bg-green-600 rounded-lg overflow-hidden my-1">
                <button
                  className="w-full px-6 py-4 text-left text-white flex items-center justify-between font-semibold"
                  onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                >
                  <span>{faq.q}</span>
                  {activeFaq === index ? (
                    <ChevronDown className="w-5 h-5" />
                  ) : (
                    <ChevronRight className="w-5 h-5" />
                  )}
                </button>
                {activeFaq === index && (
                  <div className="px-6 py-4 bg-gray-50">
                    <p>{faq.a}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="mt-8 text-center">
            <h3 className="text-3xl font-bold mb-4 text-center  text-green-600">If you do not claim benefits</h3>
            <p className="text-gray-600 mb-6">
              Even if you don't claim benefits, you might qualify through your local authority.
              Use the free eligibility checker to find out.
            </p>
            <Link
              to="/check"
              className="inline-block px-8 py-4 bg-[#2c983f] text-white rounded-lg text-lg font-semibold hover:bg-[#82cc2b] transition-all"
            >
              Check Eligibility Now
            </Link>
          </div>
        </div>
      </section>

      {/* Timer Section */}
      <section className="w-full px-4 py-16 bg-[#2c983f] text-white">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">
            The current cycle of the Energy Company Obligation known as ECO4 is predicted to run till 2026
          </h2>
          <p className="text-xl mb-8">Don't miss out and claim now, before the rule changes</p>
          
          <div className="grid grid-cols-4 gap-4 max-w-2xl mx-auto mb-8">
            {Object.entries(timeLeft).map(([unit, value]) => (
              <div key={unit} className="bg-white/10 p-4 rounded-lg">
                <div className="text-4xl font-bold">{value}</div>
                <div className="capitalize">{unit}</div>
              </div>
            ))}
          </div>
          </div>
          </section>

      {/* Final CTA Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6 text-green-600">
            What if you stop claiming benefits on return to work?
          </h2>
          <p className="text-xl mb-8">
            Don't worry if you have recently stopped claiming any benefits, you have a whole year to apply for a Grant according to Ofgem (administrator of the Energy Company Obligation Scheme).
            But to be on the safer side, it's best to apply now before the rules change.
          </p>
          <Link
            to="/check"
            className="inline-block px-8 py-4 bg-[#2c983f] text-white rounded-lg text-lg font-semibold hover:bg-[#82cc2b] hover:text-white transition-all"
          >
            Check Eligibility Now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default EcoGrantsPage;
