import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loader, AlertTriangle, LogOut, Download, Search, Trash2, X } from 'lucide-react';
import config from '../config';
import PageTitle from './PageTitle';

const AdminDashboard = () => {
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        if (!token) {
          navigate('/admin/');
          return;
        }
        const response = await axios.get(`${config.apiUrl}/api/form-entries`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setEntries(response.data);
        setFilteredEntries(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch entries. Please try again.');
        setLoading(false);
      }
    };

    fetchEntries();
  }, [navigate]);

  useEffect(() => {
    const results = entries.filter(entry =>
      Object.values(entry).some(value => 
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredEntries(results);
  }, [searchTerm, entries]);

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin/login');
  };

  const handleExportCSV = () => {
    const headers = ['Date', 'Relationship', 'Heating Type', 'Heating Age', 'Bedrooms', 'Roof Insulation', 'Wall Type', 'Benefits', 'Household Income', 'LA Flex Eligible', 'Name', 'Email', 'Telephone', 'Agree Terms'];
    const csvContent = [
      headers.join(','),
      ...filteredEntries.map(entry => [
        new Date(entry.createdAt).toLocaleDateString(),
        entry.relationship,
        entry.heatingType,
        entry.heatingAge,
        entry.bedrooms,
        entry.roofInsulation,
        entry.wallType,
        entry.benefits.join('; '),
        `£${entry.householdIncome}`,
        entry.laFlexEligible ? 'Yes' : 'No',
        entry.name,
        entry.email,
        entry.telephone,
        entry.agreeTerms ? 'Yes' : 'No'
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'form_entries.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  // In your frontend delete handler
const handleDelete = async (entryId) => {
  try {
    setDeletingId(entryId);
    const token = localStorage.getItem('adminToken');
    console.log('Attempting to delete entry:', entryId);
    console.log('Using token:', token);
    
    const response = await axios.delete(`${config.apiUrl}/api/form-entries/${entryId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log('Delete response:', response);
    
    // Update the local state to remove the deleted entry
    const updatedEntries = entries.filter(entry => entry._id !== entryId);
    setEntries(updatedEntries);
    setFilteredEntries(updatedEntries);
    setDeleteConfirm(null);
    setDeletingId(null);

  } catch (error) {
    console.error('Delete error:', error.response || error);
    setError('Failed to delete entry. Please try again.');
    setDeletingId(null);
  }
};


  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <Loader className="w-12 h-12 text-[#2c983f] animate-spin" />
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <AlertTriangle className="w-5 h-5 inline mr-2" />
        <span className="block sm:inline">{error}</span>
      </div>
    </div>
  );

  return (
    <div className="admin-dashboard p-6">
      <PageTitle title="Admin Dashboard" />
      {deleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Confirm Delete</h3>
              <button 
                onClick={() => setDeleteConfirm(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <p className="mb-4">Are you sure you want to delete this entry? This action cannot be undone.</p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteConfirm(null)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(deleteConfirm)}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center"
                disabled={deletingId === deleteConfirm}
              >
                {deletingId === deleteConfirm ? (
                  <Loader className="w-4 h-4 mr-2 animate-spin" />
                ) : (
                  <Trash2 className="w-4 h-4 mr-2" />
                )}
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-[#2c983f]">Admin Dashboard</h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleExportCSV}
            className="bg-[#2c983f] text-white px-4 py-2 rounded hover:bg-[#82cc2b] transition-colors flex items-center"
          >
            <Download className="w-4 h-4 mr-2" />
            Export CSV
          </button>
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors flex items-center"
          >
            <LogOut className="w-4 h-4 mr-2" />
            Logout
          </button>
        </div>
      </div>

      <div className="mb-4 flex items-center">
        <Search className="w-5 h-5 text-gray-400 mr-2" />
        <input
          type="text"
          placeholder="Search entries..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border rounded px-3 py-2 w-full max-w-md"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="py-3 px-4 text-left">Actions</th>
              <th className="py-3 px-4 text-left">Date</th>
              <th className="py-3 px-4 text-left">Relationship</th>
              <th className="py-3 px-4 text-left">Heating Type</th>
              <th className="py-3 px-4 text-left">Heating Age</th>
              <th className="py-3 px-4 text-left">Bedrooms</th>
              <th className="py-3 px-4 text-left">Roof Insulation</th>
              <th className="py-3 px-4 text-left">Wall Type</th>
              <th className="py-3 px-4 text-left">Benefits</th>
              <th className="py-3 px-4 text-left">Household Income</th>
              <th className="py-3 px-4 text-left">LA Flex Eligible</th>
              <th className="py-3 px-4 text-left">Name</th>
              <th className="py-3 px-4 text-left">Email</th>
              <th className="py-3 px-4 text-left">Telephone</th>
              <th className="py-3 px-4 text-left">Agree Terms</th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {filteredEntries.map((entry) => (
              <tr key={entry._id} className="border-b hover:bg-gray-50">
                <td className="py-3 px-4">
                  <button
                    onClick={() => setDeleteConfirm(entry._id)}
                    className="text-red-500 hover:text-red-700 transition-colors"
                    title="Delete entry"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </td>
                <td className="py-3 px-4">{new Date(entry.createdAt).toLocaleDateString()}</td>
                <td className="py-3 px-4">{entry.relationship}</td>
                <td className="py-3 px-4">{entry.heatingType}</td>
                <td className="py-3 px-4">{entry.heatingAge}</td>
                <td className="py-3 px-4">{entry.bedrooms}</td>
                <td className="py-3 px-4">{entry.roofInsulation}</td>
                <td className="py-3 px-4">{entry.wallType}</td>
                <td className="py-3 px-4">{entry.benefits.join(', ')}</td>
                <td className="py-3 px-4">£{entry.householdIncome}</td>
                <td className="py-3 px-4">{entry.laFlexEligible ? 'Yes' : 'No'}</td>
                <td className="py-3 px-4">{entry.name}</td>
                <td className="py-3 px-4">{entry.email}</td>
                <td className="py-3 px-4">{entry.telephone}</td>
                <td className="py-3 px-4">{entry.agreeTerms ? 'Yes' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashboard;