import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, LayoutDashboard, BookOpen, Award, MoveLeft } from 'lucide-react';
import PageTitle from './PageTitle.jsx';

const AdminLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { name: 'Dashboard', icon: LayoutDashboard, path: '/admin/dashboard' },
    { name: 'Manage Blogs', icon: BookOpen, path: '/admin/blogs' },
    { name: 'Back to Website', icon: MoveLeft, path: '/' },
  ];

  return (
    <div className="pt-32 flex h-screen bg-gray-100">
      <PageTitle title="Admin Dashboard" />
      {/* Sidebar */}
      <aside 
        className={`
          bg-[#2c983f] text-white w-64 space-y-6 py-7 px-2 fixed inset-y-0 left-0 transform 
          ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          md:relative md:translate-x-0 transition duration-300 ease-in-out z-50
        `}
      >
        <nav>
          <div className="flex items-center justify-between mb-6 px-4">
            <span className="text-2xl font-semibold">Admin Panel</span>
            <button onClick={() => setSidebarOpen(false)} className="md:hidden">
              <X className="h-6 w-6" />
            </button>
          </div>
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`
                flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200
                ${location.pathname === item.path ? 'bg-[#82cc2b] text-white' : 'text-white hover:bg-[#82cc2b]'}
              `}
            >
              <item.icon className="h-5 w-5" />
              <span>{item.name}</span>
            </Link>
          ))}
        </nav>
      </aside>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top bar */}
        <header className="bg-white shadow-md">
          <div className="flex items-center justify-between p-4">
            <button 
              onClick={() => setSidebarOpen(true)} 
              className="text-gray-500 focus:outline-none focus:text-gray-700 md:hidden"
            >
              <Menu className="h-6 w-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-700">
              {navItems.find(item => item.path === location.pathname)?.name || 'Admin'}
            </h1>
            <div>{/* You can add user profile, notifications, etc. here */}</div>
          </div>
        </header>

        {/* Page Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;