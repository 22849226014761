import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, Leaf, Home, Sun, Zap, ThermometerSun, Clock, Users, Shield, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const CheckEligibilityPage = () => {
  const [postcode, setPostcode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const postcodeRegex = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i;
      if (!postcodeRegex.test(postcode)) {
        throw new Error('Please enter a valid UK postcode');
      }
      navigate(`/check?postcode=${encodeURIComponent(postcode)}`);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="overflow-x-hidden">
       <div className="relative min-h-screen w-full">
  {/* Background image with overlay */}
  <div 
        className="pt-32 absolute inset-0 z-0"
        style={{
          backgroundImage: 'url("/assets/bghero.jpg")',
          backgroundPosition: 'right',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black/40" />
      </div>
      
      {/* Content container */}
      <div className="pt-32 relative z-10 max-w-6xl mx-auto px-4 flex items-center min-h-screen">
        <div className="w-full max-w-xl bg-white/95 backdrop-blur-sm rounded-lg p-8 shadow-xl">
          <div className="space-y-6">
            {/* Label text */}
            <p className="text-[#2c983f] text-lg font-medium">
              GOVERNMENT GRANTS
            </p>
            
            {/* Main heading */}
            <h1 className="text-4xl md:text-5xl font-bold text-gray-800">
            The Top Choice for Insulation and Heating Grants
            </h1>
            
            {/* Subtext */}
            <p className="text-gray-600 text-lg pb-4">
            Get a Free, No-Commitment Assessment to check your Eligibility Instantly
            </p>
            
            {/* CTA Button */}
            <Link to="/check" className="mt-4 bg-[#2c983f] hover:bg-[#82cc2b] text-white px-8 py-3 rounded-lg transition-colors duration-200 font-medium text-lg">
              Check Eligibility →
            </Link>
          </div>
        </div>
      </div>
    </div>

      {/* About Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row gap-12 items-center">
            
            
            <div className="w-full lg:w-1/2 space-y-8">
              <div>
                <h2 className="text-3xl font-bold text-gray-900 mb-4">About EcoShine Solar</h2>
                <p className="text-lg text-gray-700">
                EcoShine Solar is your premier Partner for Energy-Efficient upgrades in the UK. We are here to simplify the process of Securing Government Funding for Energy Grants and help you connect with Top-Rated Registered Installers with ease.
                </p>
              </div>
              
              <div className="space-y-6">
                {[
                  {
                    Icon: Clock,
                    title: 'Easy Eligibility Check',
                    desc: 'Our Easy-to-Use tool helps you discover your Eligibility for Government Grants within a Few Minutes'
                  },
                  {
                    Icon: Users,
                    title: 'Trusted Installer Network',
                    desc: 'Our Network of Trusted Installers has direct access to Government Funding, ensuring a seamless Installation process'
                  }
                ].map(({ Icon, title, desc }) => (
                  <div key={title} className="flex gap-4">
                    <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                      <Icon className="h-6 w-6 text-[#2c983f]" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold mb-1">{title}</h3>
                      <p className="text-gray-600">{desc}</p>
                    </div>
                  </div>
                ))}
              
              </div>
            </div>
            
            <div className="w-full lg:w-1/2 relative">
              <img 
                src="assets/1.webp"
                alt="Team work"
                className="w-full rounded-xl shadow-lg"
              />
              <div className="absolute -bottom-4 -right-4 bg-[#2c983f] p-4 rounded-lg shadow-lg max-w-xs text-white">
                <h3 className="text-lg font-semibold">Energy Company Obligation</h3>
                <p className="text-sm mt-1 opacity-90">Approved ECO partner</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full px-4 py-16 bg-[#2c983f]">
        <div className="max-w-3xl mx-auto text-center text-white">
          <h2 className="text-3xl font-bold mb-6">
          So why DELAY upgrading your Home? 
          </h2>
          <p className="mb-6">Take advantage of Potential Savings and Check your Eligibility for Government Energy Grants today. </p>
          <Link
            to="/check"
            className="inline-block px-8 py-3 bg-white text-[#2c983f] rounded-lg text-lg font-semibold hover:bg-[#82cc2b] hover:text-white transition-all"
          >
            Check Eligibility Now
          </Link>
        </div>
        </section>

      <section className="w-full px-4 py-16 bg-white">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-2">
          <span className="text-sm uppercase tracking-wider text-[#82cc2b]">Government’s Energy Company Obligation (ECO) | EcoShine Solar </span>
        </div>
        
        <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900 mb-4">
        Eco-efficiency grants 
        </h2>
        
        <p className="text-center text-gray-600 mb-8">
        Our program offers grants to people who struggle with high Energy Bills as compared to their Household incomes. We are accepting Applications to provide you with Suitable Grants that will help you in Reducing your Energy Bills. 
        </p>

        <div className="text-center mb-2">
          <span className="text-sm uppercase tracking-wider text-[#82cc2b]">Why choose us?</span>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 text-sm text-gray-600">
      {[
        'Completely free services',
        'Instant eligibility check',
        'No commitments',
        'Government funded energy upgrades'
      ].map((text) => (
        <div key={text} className="flex flex-col items-center">
          <div className="flex items-center">
            <CheckCircle className="h-5 w-5 text-[#82cc2b] mr-2 flex-shrink-0" />
            <span>{text}</span>
          </div>
        </div>
      ))}
    </div>

        <div className="max-w-3xl mx-auto text-center text-white">
          <Link
            to="/check"
            className="inline-block px-8 mt-6 py-3 bg-green-600 text-white rounded-lg text-lg font-semibold hover:bg-[#82cc2b] hover:text-white transition-all"
          >
            Check Eligibility Now
          </Link>
        </div>
      </div>
    </section>

      {/* Features Section */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
        <Link
            to="/check"
            className="text-3xl text-green-600 font-bold text-gray-900 mb-4"
          >
           Don’t Miss out on What You are Eligible to
          </Link><p></p>
        <Link
            to="/check"
            className="text-lg text-gray-600"
          >
            Act now and CLAIM these FREE Grants of Insulation and Heating
          </Link>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              { 
                Icon: ThermometerSun, 
                title: 'Boiler Replacement or Upgrade',
                desc: 'Incorporated as a part of the Program for Homeowners with Non-Condensing Boilers'
              },
              { 
                Icon: Zap, 
                title: 'Enhance Electric Heating',
                desc: 'Incorporated as an option where the current Heating System is Electric'
              },
              { 
                Icon: Home, 
                title: 'Insulation',
                desc: 'Our scheme offers various Insulation options such as Cavity Wall Insulation, Loft Insulation, Room-in-Roof insulation, Solid Wall insulation & Many more'
              }
            ].map(({ Icon, title, desc }) => (
              <div key={title} className="bg-white p-6 rounded-xl shadow-lg">
                <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                  <Icon className="h-6 w-6 text-[#2c983f]" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{title}</h3>
                <p className="text-gray-600">{desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Steps Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            How it works
          </h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { num: '1', title: 'Apply', desc: 'Our Application process is Hassle-Free and will only take 27 Seconds to complete' },
              { num: '2', title: 'Free Survey', desc: 'You will get a Free survey Booked as soon as possible on being Eligible' },
              { num: '3', title: 'Installation', desc: 'Easy Installation is scheduled at a Time acceptable for you and a Registered Installer' }
            ].map(({ num, title, desc }) => (
              <div key={num} className="text-center">
                <div className="w-16 h-16 bg-[#2c983f] rounded-full flex items-center justify-center mx-auto mb-6 text-white text-2xl font-bold">
                  {num}
                </div>
                <h3 className="text-xl font-semibold mb-2">{title}</h3>
                <p className="text-gray-600">{desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full px-4 py-16 bg-[#2c983f]">
        <div className="max-w-3xl mx-auto text-center text-white">
          <h2 className="text-3xl font-bold mb-6">
          What grants are you eligible for? 
          </h2>
          <Link
            to="/check"
            className="inline-block px-8 py-3 bg-white text-[#2c983f] rounded-lg text-lg font-semibold hover:bg-[#82cc2b] hover:text-white transition-all"
          >
            Check Eligibility Now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default CheckEligibilityPage;