import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { User, Calendar, ArrowLeft, Loader, AlertTriangle, Heart, Share2, BookmarkPlus, Tag } from 'lucide-react';
import config from '../config';

const BlogPost = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [liked, setLiked] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/blogs/${id}`);
        const data = await response.json();
        setBlog(data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch blog. Please try again.');
        setLoading(false);
      }
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    fetchBlog();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [id]);

  const handleShare = async () => {
    try {
      await navigator.share({
        title: blog.title,
        text: blog.description,
        url: window.location.href,
      });
    } catch (error) {
      await navigator.clipboard.writeText(window.location.href);
    }
  };

  if (loading) return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-green-50 via-emerald-50 to-teal-50">
      <div className="p-8 rounded-2xl bg-white/80 backdrop-blur-sm shadow-xl">
        <Loader className="w-16 h-16 text-emerald-600 animate-spin" />
        <p className="mt-4 text-emerald-800 font-medium animate-pulse">Loading your eco-friendly content...</p>
      </div>
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-green-50 via-emerald-50 to-teal-50 p-4">
      <div className="bg-white/80 backdrop-blur-sm border-l-4 border-red-500 p-6 rounded-xl shadow-xl max-w-md w-full">
        <div className="flex items-center">
          <AlertTriangle className="w-6 h-6 text-red-500 mr-3" />
          <p className="text-red-700 font-medium">{error}</p>
        </div>
      </div>
    </div>
  );

  if (!blog) return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-green-50 via-emerald-50 to-teal-50 p-4">
      <div className="bg-white/80 backdrop-blur-sm border-l-4 border-yellow-500 p-6 rounded-xl shadow-xl max-w-md w-full">
        <div className="flex items-center">
          <AlertTriangle className="w-6 h-6 text-yellow-500 mr-3" />
          <p className="text-yellow-700 font-medium">Blog post not found</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="pt-32 min-h-screen bg-gradient-to-br from-green-50 via-emerald-50 to-teal-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <nav className={`top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? 'bg-white/80 backdrop-blur-sm shadow-md' : ''
        }`}>
          <div className="max-w-4xl mx-auto px-4 py-4">
            <Link 
              to="/blogs" 
              className="inline-flex items-center text-emerald-700 hover:text-emerald-500 px-4 py-2 rounded-full bg-white/80 hover:bg-emerald-100 transition-all duration-300 group shadow-sm hover:shadow-md"
            >
              <ArrowLeft className="w-4 h-4 mr-2 transform group-hover:-translate-x-1 transition-transform duration-300" />
              Back to Blog List
            </Link>
          </div>
        </nav>

        <div className="mt-16">
          <div className="bg-white/80 backdrop-blur-sm rounded-3xl shadow-xl hover:shadow-2xl transition-all duration-500 overflow-hidden">
            {blog.featuredImage && (
              <div className="relative h-56 overflow-hidden">
                <img 
                  src={blog.featuredImage.startsWith('/') ? blog.featuredImage : `/${blog.featuredImage}`}
                  alt={blog.title}
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                />
              </div>
            )}
            
            <div className="p-8 md:p-12">
              {!blog.featuredImage && (
                <h1 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-emerald-600 to-teal-600 bg-clip-text text-transparent mb-8 leading-tight">
                  {blog.title}
                </h1>
              )}
              
              <div className="flex flex-wrap items-center gap-4 text-sm mb-8 pb-6 border-b border-emerald-100">
                <div className="flex items-center bg-emerald-50 px-4 py-2 rounded-full shadow-sm hover:shadow-md transition-shadow duration-300">
                  <User className="w-5 h-5 mr-2 text-emerald-600" />
                  <span className="font-medium text-emerald-800">{blog.author}</span>
                </div>
                <div className="flex items-center bg-emerald-50 px-4 py-2 rounded-full shadow-sm hover:shadow-md transition-shadow duration-300">
                  <Calendar className="w-5 h-5 mr-2 text-emerald-600" />
                  <span className="text-emerald-800">{new Date(blog.createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}</span>
                </div>
                {blog.tags && (
                  <div className="flex items-center bg-emerald-50 px-4 py-2 rounded-full shadow-sm hover:shadow-md transition-shadow duration-300">
                    <Tag className="w-5 h-5 mr-2 text-emerald-600" />
                    <span className="text-emerald-800">{blog.tags.join(', ')}</span>
                  </div>
                )}
              </div>
              
              <div 
              className="ql-editor prose prose-lg max-w-none mb-12
                prose-headings:text-emerald-900 
                prose-h1:text-4xl prose-h1:font-bold prose-h1:mb-6
                prose-h2:text-3xl prose-h2:font-semibold prose-h2:mb-4
                prose-h3:text-2xl prose-h3:font-medium prose-h3:mb-3
                prose-p:text-gray-700 prose-p:mb-4
                prose-a:text-emerald-600 hover:prose-a:text-emerald-700
                prose-strong:text-emerald-900
                prose-em:text-emerald-800
                prose-code:text-emerald-600 prose-code:bg-emerald-50 prose-code:rounded prose-code:px-1
                prose-pre:bg-gray-900 prose-pre:text-gray-100 prose-pre:p-4 prose-pre:rounded-lg
                prose-img:rounded-xl prose-img:shadow-lg
                prose-blockquote:border-l-4 prose-blockquote:border-emerald-500 prose-blockquote:pl-4 prose-blockquote:italic
                prose-ul:list-disc prose-ul:ml-6
                prose-ol:list-decimal prose-ol:ml-6
                prose-li:mb-2
                [&_.ql-align-center]:text-center
                [&_.ql-align-right]:text-right
                [&_.ql-align-justify]:text-justify
                [&_.ql-indent-1]:ml-4
                [&_.ql-indent-2]:ml-8
                [&_.ql-size-small]:text-sm
                [&_.ql-size-large]:text-lg
                [&_.ql-size-huge]:text-xl"
              dangerouslySetInnerHTML={{ __html: blog.body }} 
            />
            

              <div className="flex flex-wrap items-center justify-between gap-4 pt-6 border-t border-emerald-100">
                <div className="flex flex-wrap gap-4">
                  <button 
                    onClick={() => setLiked(!liked)}
                    className={`flex items-center gap-2 px-6 py-3 rounded-full transition-all duration-300 ${
                      liked 
                        ? 'bg-red-50 text-red-600 shadow-inner scale-105' 
                        : 'bg-white text-gray-600 hover:bg-red-50 hover:text-red-600 shadow-sm hover:shadow-md'
                    }`}
                  >
                    <Heart className={`w-5 h-5 ${liked ? 'fill-current' : ''} transform hover:scale-110 transition-transform duration-200`} />
                    <span>{liked ? 'Liked' : 'Like'}</span>
                  </button>
                  
                  <button 
                    onClick={() => setBookmarked(!bookmarked)}
                    className={`flex items-center gap-2 px-6 py-3 rounded-full transition-all duration-300 ${
                      bookmarked 
                        ? 'bg-emerald-100 text-emerald-700 shadow-inner scale-105' 
                        : 'bg-white text-gray-600 hover:bg-emerald-50 hover:text-emerald-600 shadow-sm hover:shadow-md'
                    }`}
                  >
                    <BookmarkPlus className={`w-5 h-5 transform hover:scale-110 transition-transform duration-200 ${bookmarked ? 'fill-current' : ''}`} />
                    <span>{bookmarked ? 'Saved' : 'Save'}</span>
                  </button>
                </div>
                
                <button 
                  onClick={handleShare}
                  className="flex items-center gap-2 px-6 py-3 rounded-full bg-white text-gray-600 hover:bg-emerald-50 hover:text-emerald-600 transition-all duration-300 shadow-sm hover:shadow-md"
                >
                  <Share2 className="w-5 h-5 transform hover:scale-110 transition-transform duration-200" />
                  <span>Share</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;