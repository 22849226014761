import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon } from 'lucide-react';

const NotFound = () => {
  return (
    <div className="min-h-screen relative overflow-hidden bg-gradient-to-b from-gray-50 to-white">
      {/* Decorative circles */}
      <div className="absolute top-0 left-0 w-96 h-96 bg-green-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
      <div className="absolute top-0 right-0 w-96 h-96 bg-[#82cc2b] rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
      <div className="absolute -bottom-8 left-20 w-96 h-96 bg-[#2c983f] rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>

      <div className="relative pt-32 px-4 flex items-center justify-center min-h-screen">
        <div className="max-w-2xl mx-auto text-center backdrop-blur-sm bg-white/80 p-8 rounded-2xl shadow-xl transform hover:scale-105 transition-transform duration-300">
          <div className="relative mb-8">
            <h1 className="text-9xl font-bold text-gray-900 opacity-10">404</h1>
            <h2 className="text-4xl font-bold text-gray-800 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              Oops!
            </h2>
          </div>
          
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">
            Page Not Found
          </h3>
          
          <p className="text-gray-600 mb-8 max-w-md mx-auto">
            We can't seem to find the page you're looking for. It might have been moved or deleted.
          </p>
          
          <Link
            to="/"
            className="inline-flex items-center px-6 py-3 bg-[#2c983f] text-white rounded-lg font-medium 
                       hover:bg-[#82cc2b] transition-all duration-300 transform hover:scale-105 
                       shadow-lg hover:shadow-xl gap-2"
          >
            <HomeIcon className="w-5 h-5" />
            <span>Back to Home</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;