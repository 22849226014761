import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PlusCircle, Edit2, Trash2, AlertTriangle, Loader } from 'lucide-react';
import config from '../config';

const BlogManagement = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        if (!token) {
          navigate('/admin/');
          return;
        }
        const response = await axios.get(`${config.apiUrl}/api/blogs`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setBlogs(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch blogs. Please try again.');
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const deleteBlog = async (id) => {
    if (window.confirm('Are you sure you want to delete this blog?')) {
      try {
        const token = localStorage.getItem('adminToken');
        await axios.delete(`${config.apiUrl}/api/blogs/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setBlogs(blogs.filter(blog => blog._id !== id));
      } catch (error) {
        setError('Failed to delete blog. Please try again.');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <Loader className="w-12 h-12 text-[#2c983f] animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-green-50 to-white">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-6 bg-[#2c983f] text-white flex justify-between items-center">
            <h1 className="text-3xl font-bold">Blog Management</h1>
            <Link
              to="/admin/blogs/new"
              className="flex items-center bg-white text-[#2c983f] px-4 py-2 rounded-lg hover:bg-[#82cc2b] hover:text-white transition-colors"
            >
              <PlusCircle className="w-5 h-5 mr-2" />
              Create New Blog
            </Link>
          </div>

          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 m-6">
              <div className="flex items-center">
                <AlertTriangle className="w-6 h-6 text-red-400 mr-2" />
                <p className="text-red-700">{error}</p>
              </div>
            </div>
          )}

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Author</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {blogs.map((blog) => (
                  <tr key={blog._id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">{blog.title}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{blog.author}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{new Date(blog.createdAt).toLocaleDateString()}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex space-x-2">
                        <Link
                          to={`/admin/blogs/edit/${blog._id}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <Edit2 className="w-5 h-5" />
                        </Link>
                        <button
                          onClick={() => deleteBlog(blog._id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogManagement;