import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  CheckCircle,
  ThermometerSun,
  Zap,
  Home,
  ChevronRight,
  ChevronDown,
  HelpCircle
} from 'lucide-react';

const LADSPage = () => {
  const [activeFaq, setActiveFaq] = useState(null);

  return (
    <div className="overflow-x-hidden">
      <section className="w-full mt-32 px-4 py-16 bg-[#2c983f]">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-white">Local Authority Delivery (LAD)| EcoShine Solar</h1>
        </div>
      </section>

      {/* Breadcrumb Banner */}
      <div className="bg-gray-100 py-3 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center justify-center gap-2 text-sm">
            <Link to="/" className="text-[#2c983f] hover:text-[#82cc2b]">Home</Link>
            <ChevronRight className="w-4 h-4" />
            <Link to="/grants" className="text-[#2c983f] hover:text-[#82cc2b]">Grants</Link>
            <ChevronRight className="w-4 h-4" />
            <span className="text-gray-600">LAD</span>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <section className="w-full px-4 py-16 bg-gradient-to-r from-white">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row gap-12 items-center">
            <div className="w-full lg:w-1/2 space-y-6">
              <h2 className="text-3xl text-green-600 font-bold">Eco-Efficiency Grants</h2>
              <p className="text-xl">Local Authority Delivery Scheme provides Grants to Households earning less than £30K per year. This means you are only Eligible for this Scheme if you earn less than £30K per annum. Moreover, to be able to get Grants under the Local Authority Delivery (LAD) Scheme your House Energy Performance Certificate (EPC) Rating should be E, F, or G.</p>
              <p className="text-xl">A wide Range of Insulation, Heating, and Renewable Energy Grants are being provided under LAD</p>
            </div>
            <div className="w-full lg:w-1/2">
              <img 
                src="/assets/1.jpg" 
                alt="Energy efficiency" 
                className="rounded-xl shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-green-600 text-center mb-8">Why choose us?</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              'Completely Free',
              'Obligation Free',
              'Easy 2-Minute Application Process ',
              'No Restrictions '
            ].map((benefit, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg flex items-center gap-3">
                <CheckCircle className="w-5 h-5 text-[#2c983f]" />
                <span className="font-medium">{benefit}</span>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <Link
              to="/check"
              className="inline-block px-8 py-4 bg-[#2c983f] text-white rounded-lg text-lg font-semibold hover:bg-[#82cc2b] transition-all"
            >
              CHECK ELIGIBILITY NOW
            </Link>
          </div>
        </div>
      </section>

      {/* Available Grants */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-green-600 mb-4">Energy Efficient Grants</h2>
          <p className="text-xl text-center mb-12">Avail the Opportunity to get these Free Grants for Insulation and Heating today.</p>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 justify-around">
            {[
              {
                icon: ThermometerSun,
                title: 'Renewable Energy',
                desc: 'Solar Panels and Heat Pump choices are accessible under this Scheme.'
              },
              {
                icon: Zap,
                title: 'Electric Heating Upgrade',
                desc: 'Especially for the Households using Electricity for Heating.'
              },
              {
                icon: Home,
                title: 'First Time Central Heating',
                desc: 'Properties that never had Central Heating ever, can get it done under this Scheme.'
              },
              {
                icon: Home,
                title: 'Insulation',
                desc: 'Cavity Wall Insulation, Underfloor Insulation, Loft Insulation, Solid Wall Insulation, and Room-In Insulation, all these Insulations are available under LAD Scheme.'
              }
            ].map((grant, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                  <grant.icon className="h-6 w-6 text-[#2c983f]" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{grant.title}</h3>
                <p className="text-gray-600 mb-4">{grant.desc}</p>
                <Link
                  to="/check"
                  className="text-[#2c983f] font-semibold hover:text-[#82cc2b] flex items-center"
                >
                  Check Eligibility <ChevronRight className="ml-1 w-4 h-4" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-green-600 mb-4">Process</h2>
          <p className="text-center mb-12">Hassle-free application process</p>
          <p className="text-center mb-12">Check your Eligibility while sipping up your favorite coffee</p>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: 'Apply',
                desc: 'Simple and easy process only takes a minute or 2'
              },
              {
                title: 'Survey',
                desc: 'Free survey of the property, once found eligible'
              },
              {
                title: 'Installation',
                desc: 'Installation scheduled at a time suitable for Registered Installers'
              }
            ].map((step, index) => (
              <div key={index} className="text-center">
                <div className="w-16 h-16 bg-[#2c983f] rounded-full flex items-center justify-center mx-auto mb-6 text-white text-2xl font-bold">
                  {index + 1}
                </div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4 text-green-600">FAQs</h2>
            <p className="mb-4">Feel free to contact us for any questions or queries. We are always happy to help you in every way possible.</p>
          </div>

          <div className="space-y-4s">
            {[
              {
                q: 'How do I know if I am eligible for the LAD Scheme?',
                a: 'To be Eligible for the LAD Scheme, Households need to have a maximum income of £30K per year.'
              },
              {
                q: 'Can Landlords or Tenants get Grants?',
                a: 'Yes, Landlords and Tenants can get Grants, but only for two-thirds of the cost, up to £5,000.'
              },
              {
                q: 'How to know about my EPC Rating?',
                a: 'People living in England and Wales can find out about the EPC Rating of their property via the EPC Register. Whereas, the people of Scotland can visit the Scottish EPC Register to check their EPC Ratings.'
              },
              {
                q: "What if I don't have an EPC?",
                a: 'In case of not having any EPC, the installer will carry out an Energy Assessment Procedure to check if your property qualifies for funding or not.'
              }
            ].map((faq, index) => (
              <div key={index} className="bg-green-600 rounded-lg overflow-hidden my-1">
                <button
                  className="w-full px-6 py-4 text-left text-white flex items-center justify-between font-semibold"
                  onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                >
                  <span>{faq.q}</span>
                  {activeFaq === index ? (
                    <ChevronDown className="w-5 h-5" />
                  ) : (
                    <ChevronRight className="w-5 h-5" />
                  )}
                </button>
                {activeFaq === index && (
                  <div className="px-6 py-4 bg-gray-50">
                    <p>{faq.a}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="w-full px-4 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center text-green-600">Who Qualifies for the Scheme?</h2>
          <p className="text-xl text-center mb-8">The Scheme is designed to be a helping hand for people having High Fuel Costs and Low Income. You may also qualify for a Grant if someone in your family or Household receives any Government Benefits. Additionally, identification as "Fuel Poor" or "Low-Income and Vulnerable" from a Local Council can also make you Eligible for the Scheme. Even if you don't claim benefits, you may still qualify through Local Authority Funding Rules if your fuel costs are high.</p>
          <div className="space-y-4s">
            {[
              {
                q: 'EPC Rating of E, F, or G',
                a: "Your home must have an EPC Rating of E, F, or G. If you don't know about the EPC Rating of your home then you can use our Grant Checker to check if you qualify or not in less than a minute."
              },
              {
                q: 'Household Income less than £30K per year',
                a: "You need to show with evidence, payslips, and bank statements that your overall household income doesn't exceed  £30K per annum to qualify for the Scheme."
              },
              {
                q: 'Earn over £30K, but claim Benefits',
                a: 'If you earn more than £30K but have claimed some Government Benefits in a year or half, then there is a possibility that you can qualify for the Scheme. You can always use our Grant Checker to check your Eligibility.'
              }
            ].map((faq, index) => (
              <div key={index} className="bg-green-600 rounded-lg overflow-hidden my-1">
                <button
                  className="w-full px-6 py-4 text-left text-white flex items-center justify-between font-semibold"
                  onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                >
                  <span>{faq.q}</span>
                  {activeFaq === index ? (
                    <ChevronDown className="w-5 h-5" />
                  ) : (
                    <ChevronRight className="w-5 h-5" />
                  )}
                </button>
                {activeFaq === index && (
                  <div className="px-6 py-4 bg-gray-50">
                    <p>{faq.a}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>


      {/* Final CTA Section */}
      <section className="w-full px-4 py-16 bg-[#2c983f]">
        <div className="max-w-3xl mx-auto text-center text-white">
          <h2 className="text-3xl font-bold mb-6">
            What grants are you eligible for? 
          </h2>
          <Link
            to="/check"
            className="inline-block px-8 py-3 bg-white text-[#2c983f] rounded-lg text-lg font-semibold hover:bg-[#82cc2b] hover:text-white transition-all"
          >
            Check Eligibility Now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default LADSPage;