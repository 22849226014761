import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';

const GlobalImageLoader = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    let imageElements = [];
    let loadedImages = 0;
    
    const updateProgress = () => {
      loadedImages++;
      const newProgress = Math.round((loadedImages / imageElements.length) * 100);
      setProgress(newProgress);
      
      if (loadedImages === imageElements.length) {
        setLoading(false);
      }
    };

    // Function to handle new images being added to the DOM
    const observeImages = () => {
      // Reset counters when new observation starts
      imageElements = [];
      loadedImages = 0;
      
      // Find all images in the document
      const images = document.getElementsByTagName('img');
      imageElements = Array.from(images);
      
      if (imageElements.length === 0) {
        setLoading(false);
        return;
      }

      imageElements.forEach(img => {
        if (img.complete) {
          updateProgress();
        } else {
          img.addEventListener('load', updateProgress);
          img.addEventListener('error', updateProgress);
        }
      });
    };

    // Create an observer to watch for DOM changes
    const observer = new MutationObserver((mutations) => {
      const hasNewImages = mutations.some(mutation => 
        Array.from(mutation.addedNodes).some(node => 
          node.nodeName === 'IMG' || (node.getElementsByTagName && node.getElementsByTagName('img').length > 0)
        )
      );

      if (hasNewImages) {
        observeImages();
      }
    });

    // Start observing the document
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    // Initial observation
    observeImages();

    // Cleanup
    return () => {
      observer.disconnect();
      imageElements.forEach(img => {
        img.removeEventListener('load', updateProgress);
        img.removeEventListener('error', updateProgress);
      });
    };
  }, []);

  return (
    <>
      {loading && (
        <div className="fixed inset-0 bg-white bg-opacity-90 flex items-center justify-center z-50">
          <div className="text-center">
            <Loader2 className="w-12 h-12 animate-spin text-[#2c983f] mx-auto mb-4" />
            <p className="text-gray-600">Loading content...</p>
            <div className="mt-4 w-64">
              <div className="bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-[#2c983f] h-2.5 rounded-full transition-all duration-300" 
                  style={{ width: `${progress}%` }}
                />
              </div>
              <p className="text-sm text-gray-400 mt-2">{progress}% loaded</p>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default GlobalImageLoader;