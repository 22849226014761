import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Search, Users, FileText } from 'lucide-react';

const SectionDivider = ({ title, icon }) => (
  <div className="w-full bg-green-600 rounded-lg p-8 mb-8 text-center">
    <div className="flex justify-center mb-2">
      {icon}
    </div>
    <h2 className="text-2xl font-bold text-white">{title}</h2>
  </div>
);

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`border rounded-lg mb-4 transition-all duration-300 ${
      isOpen ? 'bg-green-50 border-green-300' : 'border-green-200'
    }`}>
      <button
        className="flex justify-between items-center w-full text-left p-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-medium text-green-800">{question}</h3>
        {isOpen ? <ChevronUp className="text-green-600" /> : <ChevronDown className="text-green-600" />}
      </button>
      <div className={`overflow-hidden transition-all duration-300 ${
        isOpen ? 'max-h-[500px]' : 'max-h-0'
      }`}>
        <div className="p-4 pt-0 text-green-700">{answer}</div>
      </div>
    </div>
  );
};

const FAQPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const faqs = {
    general: [
      {
        question: "What are the energy-efficient government grant options available in the UK?",
        answer: "Currently, the government of the UK is offering 2 major Grants to improve household energy efficiency and reduce the load of energy bills: ECO4 Scheme specifically for low-income households, providing whole house upgrades, and Great British Insulation Scheme provides one insulation upgrade per household."
      },
      {
        question: "What is the ECO4 Scheme?",
        answer: "It is the most current scheme of the energy company obligation. Specifically curated for the low-income and vulnerable households to upgrade their homes and reduce the consumption of energy. Various energy-saving measures are provided under this scheme for example: insulation, heating upgrades renewable energy installation. Eligible homes can even get a whole home upgrade via this scheme."
      },
      {
        question: "What is the eligibility criteria for the ECO4 scheme?",
        answer: "The eligibility criteria for the ECO4 scheme are: You are the recipient of income-related benefits like pension credit, Universal Credit, or tax credit. Have a D, E, F, or G EPC rating of the living household. Your eligibility is in your local council's ECO Flex criteria, in which low-income and health issues are considered."
      },
      {
        question: "What is the Great British Insulation Scheme?",
        answer: "The GBIS is a government step towards insulating 300,000 homes a year. It basically includes those houses that do not qualify for the ECO4 scheme but still require insulation upgrades."
      },
      {
        question: "Eligibility criteria for the GBIS?",
        answer: "GBIS is applicable to households that Contains EPC rating of D, E, F, or G (or no EPC rating). General group households with Council Tax rating of A-D in England and A-E in Scotland and Wales."
      },
      {
        question: "How to apply for government Grants in the UK?",
        answer: "Eco Solar Shine brings you an easy and hassle-free process of applying for any government energy Grants available in the UK. Use our free grant checker to find out your eligibility by just inserting your household details and you will get to know about your grant eligibility even in less than a minute. Once you qualify we'll provide you with a seamless pathway of the whole application procedure and provide you with all necessary arrangements for home upgrade."
      },
      {
        question: "What are the various energy-efficient measures that the UK government is offering?",
        answer: "UK government is offering an enormous range of energy-efficient measures such as: Loft insulation, Cavity wall insulation, Roof insulation, Solid wall insulation, Heat pump grants, Boiler replacements (for eligible households under ECO4), Broken boiler grants, Solar panel installations"
      },
      {
        question: "Are government grants available for landlords in the UK?",
        answer: "Yes, landlords can apply if their property meets the eligibility criteria. It will provide a massive improvement in saving energy but the range of grants will vary as per the scheme."
      },
      {
        question: "Is there any amount to pay to get the government grants in the UK?",
        answer: "In many cases, No, but sometimes a contribution may be required as per the installing needs if it exceeds £7500. You will always be provided with every potential information beforehand."
      },
      {
        question: "What is the time period for receiving a government energy grant?",
        answer: "The type of upgrade and your circumstances decide the time frame. ECO4 energy grants can take up a lot of time as they improve the complete house as compared to GBIS grants which require a single insulation installation. From the beginning of the application process to the completion, the time frame can be anywhere between a few days or even months."
      },
      {
        question: "What is the difference between ECO4 grants and GBIS grants?",
        answer: "ECO4: specifically for low-income households and targets the whole house with a variety of energy measures. GBIS: only fund a single insulation installation and include two categories of households i.e., Low-income General"
      },
      {
        question: "How are these energy-efficient grants beneficial for energy savings in the UK?",
        answer: "These grants actually help you save a significant amount of your energy expenditure. Makes your home more cozy for the harsh winter weather, and plays a great role in reducing your carbon footprint. House improvements through these grants can let you enjoy long-term comfort with savings with ecological advantages."
      },
      {
        question: "How can Eco Solar Shine help with these grants?",
        answer: "We are here to break down barriers between you and the government grants. From start to end we make sure to make this whole process hassle-free for you just to make your home energy efficient. Use our free eligibility checker to see how we guide you to the right path."
      }
    ],
    applicationProcess: [
      {
        question: "What is the duration of the application process?",
        answer: "The process is pretty fast if the installer has complete documentation and the paperwork is completed on time. You can check for our complete guide on how to apply for an ECO grant in simple steps for a detailed description and to have a smooth application process."
      },
      {
        question: "What and why data match is important?",
        answer: "To comply with Ofgem rules, all grant applicants must undergo a data match process. This is handled by Eco Solar Shine. Please complete our online consent form to expedite the application process. Installers must perform a data match to verify eligibility, regardless of the application reason (benefits, health, or income). They submit a data match request to the DWP through the Energy Saving Trust."
      },
      {
        question: "Who should I speak to after the allocation is completed?",
        answer: "You will directly contact the installer as the contract will be between you and him. We are only here to provide you with the necessary guidance and instructions which can be done at any time from the start to the completion of installation. We are happy to help you in every possible."
      }
    ],
    documentsAndEvidence: [
      {
        question: "Why there is a need to provide a utility bill?",
        answer: "Proof of residency is essential for qualifying for the grant installation. It actually proves your living on that property. However, as per the Ofgem guidelines any of the following documents can be provided: Utility bill, landline bill, or TV license, Council tax letter, Bank statement or mortgage statement, Agreement of your tenancy. The documents should be dated within the past three months of the installation and show all pages and all four corners of official documents. For details visit Ofgem guidance."
      },
      {
        question: "How can I show the proof of ownership?",
        answer: "If you are registered in the Land Registry then there is no need to show anything as the installer will use the official documents to show the proof of ownership as per Ofgem guidance. However, if the property is newly purchased and is not registered on the Land Registry yet, then you have to submit the completion letter of your evidence ownership."
      }
    ],
    ecoFourGrants: [
      {
        question: "Explain ECO4",
        answer: "ECO4, or the Energy Company Obligation Phase 4, is the latest iteration of a UK government initiative aimed at improving energy efficiency in low-income households. The scheme offers financial support for measures such as insulation, heating upgrades, and renewable energy systems to help residents lower energy bills and reduce carbon emissions. ECO4 prioritizes properties with poor energy efficiency, specifically targeting homes with Energy Performance Certificate (EPC) ratings of D, E, F, or G. However, energy suppliers are restricted to allocating only 15% of their funding toward Band D properties. As a result, most of the assistance under this phase is directed toward homes with EPC ratings of E, F, or G."
      },
      {
        question: "What is the minimum EPC rating increase needed for an ECO4 grant?",
        answer: "To qualify for an ECO4 grant, properties must improve their EPC rating as follows: D-rated properties to C, E-rated properties to C, F-rated properties to D, G-rated properties to D."
      },
      {
        question: "What grants are available through the ECO4 scheme?",
        answer: "The ECO4 scheme provides funding for a range of energy efficiency measures, including: Upgrading outdated heating systems to modern, energy-efficient alternatives, Installing effective insulation to reduce heat loss, Incorporating renewable energy technologies, such as solar panels, Implementing smart home technology to optimize energy consumption."
      },
      {
        question: "What are the eligibility criteria for ECO4 grants?",
        answer: "To qualify for an ECO4 grant, households must: Be in receipt of specific government benefits, Occupy a property with a low Energy Performance Certificate (EPC) rating and inefficient heating systems, Meet additional eligibility requirements, which may vary by local authority, such as vulnerability to fuel poverty or specific health conditions."
      },
      {
        question: "What manufacturer warranties are provided with grants installations?",
        answer: "Energy-saving measures funded by ECO4 and GBIS typically include manufacturer warranties as follows: Boilers: 2-year warranty, Heat pumps and renewable energy systems: Warranties of up to 10-25 years, Insulation: Warranties of up to 25 years."
      },
      {
        question: "Can the guarantee be extended?",
        answer: "Yes, extension plans are available but you must need to ask to your installer first about the available plans. As most companies provide extended warranty choice on additional payments. However, it is worth a shot if you wish you have long-term protection of your energy-efficient items like boilers."
      },
      {
        question: "What insurance is included in energy grants?",
        answer: "Government-funded energy installations typically include insurance coverage for: Workmanship: 2-year guarantee, Property damage: Public liability insurance (protects you from the load of getting any damage during installation), Installer insolvency: Insurance-backed guarantee. Insurance helps you to be in peace and makes energy-efficient grants installation possible for more households."
      }
    ],
    eligibility: [
      {
        question: "While no one in our household has asthma, my husband does suffer from Raynaud's syndrome and hypothyroidism, conditions that are exacerbated by cold temperatures. Would these conditions qualify him for a grant?",
        answer: "The ultimate decision regarding eligibility lies with the referring GP. If they believe these conditions warrant a grant referral, we can proceed with the application."
      },
      {
        question: "Suffering from hypothyroidism which makes me feel colder a lot as compared to others. Does this make me eligible?",
        answer: "The ultimate decision regarding eligibility lies with the referring GP. If they believe these conditions warrant a grant referral, we can proceed with the application."
      },
      {
        question: "Would having asthma qualify me for the grant?",
        answer: "We've helped many asthma sufferers get approved for HEAL grants. However, your GP needs to determine your eligibility and complete the referral."
      }
    ],
    complaintsAndIssues: [
      {
        question: "What will be the procedure in case of any complaints and issues after installation?",
        answer: "The Government Grant Schemes come with their standard guarantees and manufacturer warranties. The applicant also receives an insurance-backed guarantee on the Trustmark Data Warehouse. However, for any issues or complaints, you can always visit https://www.ofgem.gov.uk/eco4-complaints-process for better understanding. However, according to Trademark, the recommended procedure is Process your complaint via writing (email is also fine) to have a record. And connect to the installer as he is the one in contract. Provide a detailed explanation of your issue so that the installer can address it accordingly. Give some time from your end to the installer. In case of responsiveness from the installer, you can always proceed with the details at Ofgem site."
      }
    ],
    externalWallInsulation: [
      {
        question: "Can I get external wall insulation via any scheme?",
        answer: "Regrettably, external wall insulation is not typically included in standard grant offerings. Instead, we have observed a higher success rate for internal wall insulation grant applications. This is primarily attributed to the significantly lower installation costs associated with internal wall insulation, making it more feasible within the available funding."
      }
    ],
    windowGrants: [
      {
        question: "I am interested in replacing my windows. Are there any government grants available to assist with this?",
        answer: "Unfortunately, current government grant schemes do not provide funding for window replacements. The primary focus of UK government grants is on insulation, heating system upgrades, and renewable energy installations. While window replacements are listed as a potential grant category by Ofgem, the available funding does not cover installation costs, resulting in a limited number of successful window grant applications."
      }
    ],
    solarGrants: [
      {
        question: "I was hoping to qualify for a solar panel grant. Is this a possibility?",
        answer: "At present, we have not observed any major government grant schemes offering standalone solar panel grants. The Energy Company Obligation (ECO4) scheme does include solar panels as part of a 'whole house' upgrade. However, eligibility requires an EPC rating of E, F, or G, and the presence of an inefficient heating system (such as a heat pump or high-heat retention electric storage heaters). Unfortunately, homes heated by mains gas are not eligible for solar grants under current Ofgem regulations."
      }
    ],
    otherQuestions: [
      {
        question: "I would like to hire my own installer. Can I pay for the installation upfront and then claim the grant retrospectively?",
        answer: "Unfortunately, government grants cannot be claimed retrospectively. To be eligible for a grant, you must apply through a registered installer who has a contract with the relevant funder or scheme provider."
      }
    ]
  };

  const getAllFaqs = () => {
    if (!searchTerm) return null;
    return [
      ...faqs.general, 
      ...faqs.applicationProcess, 
      ...faqs.documentsAndEvidence,
      ...faqs.ecoFourGrants,
      ...faqs.eligibility,
      ...faqs.complaintsAndIssues,
      ...faqs.externalWallInsulation,
      ...faqs.windowGrants,
      ...faqs.solarGrants,
      ...faqs.otherQuestions
    ].filter(faq =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filteredFaqs = getAllFaqs();

  return (
    <div className="pt-32 min-h-screen bg-gradient-to-b from-green-50 to-white py-12">
      <div className="w-full max-w-3xl mx-auto px-4">
        <h1 className="text-2xl sm:text-4xl font-bold text-green-800 text-center mb-4 break-words">
          Government Grants UK – Frequently Asked Questions (FAQs)
        </h1>
        <p className="text-green-700 text-center mb-8">Last updated: November 25, 2024</p>
        
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-sm mb-8">
          <p className="text-green-700 break-words">
            At Eco Solar Shine, we simplify the process of accessing UK government grants for home energy efficiency. 
            Whether you're interested in ECO4 or GBIS, we're here to guide you through the application process and 
            maximize your energy savings.
          </p>
        </div>

        <div className="mb-8 relative">
          <input
            type="text"
            placeholder="Search FAQs..."
            className="w-full p-4 pl-12 text-green-700 border border-green-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-4 top-4 text-green-400" />
        </div>

        {filteredFaqs ? (
          <div className="space-y-2">
            {filteredFaqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        ) : (
          <>
            <div className="space-y-2 mb-12">
              {faqs.general.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="Grant application process" 
              icon={<Users className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.applicationProcess.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="Documents and evidence requirements" 
              icon={<FileText className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.documentsAndEvidence.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="ECO4 Grants" 
              icon={<FileText className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.ecoFourGrants.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="Eligibility" 
              icon={<Users className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.eligibility.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="Complaints and Issues" 
              icon={<FileText className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.complaintsAndIssues.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="External Wall Insulation" 
              icon={<FileText className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.externalWallInsulation.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="Window Grants" 
              icon={<FileText className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.windowGrants.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="Solar Grants" 
              icon={<FileText className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.solarGrants.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>

            <SectionDivider 
              title="Other Questions" 
              icon={<Users className="w-8 h-8 sm:w-12 sm:h-12 text-white" />}
            />
            <div className="space-y-2 mb-12">
              {faqs.otherQuestions.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </>
        )}

        <div className="text-center mt-12">
          <p className="text-green-700">
            Do you have another question in your mind that is not answered here? 
            You can always contact us for your questions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;