import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const GrantList = () => {
  const grants = [
    {
      title: "Government's Energy Company Obligation (ECO)",
      description: "Through the Energy Company Obligation Scheme, LOW-Income Individuals and Households with High Fuel Costs can access Essential Grants.",
      image: "/assets/solar1.jpg",
      link: "/grants/eco4"
    },
    {
      title: "Great British Insulation Scheme",
      description: "Various Grants are being provided to UK households under the Great British Insulation Scheme.",
      image: "/assets/gbis1.jpg",
      link: "/grants/gbis"
    },
    {
      title: "Local Authority Delivery (LAD)",
      description: "For Low-Income Households in England (earning under £30k) with Low Energy Efficiency Ratings (EPC D or lower), Government Schemes Fund essential Energy Upgrades.",
      image: "/assets/1.jpg",
      link: "/grants/lads"
    }
  ];

  return (
    <div className="overflow-x-hidden">
      <section className="w-full mt-32 px-4 py-16 bg-[#2c983f]">
        <div className='text-center'>
          <h1 className="text-4xl font-bold text-white">Grants</h1>
        </div>
      </section>

      <div className="bg-gray-100 py-3 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center justify-center gap-2 text-sm">
            <Link to="/" className="text-[#2c983f] hover:text-[#82cc2b]">Home</Link>
            <ChevronRight className="w-4 h-4" />
            <span className="text-gray-600">Grants</span>
          </div>
        </div>
      </div>

      <section className="w-full px-4 py-16 bg-white">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-3 gap-8">
            {grants.map((grant, index) => (
              <Link 
                to={grant.link} 
                key={index} 
                className="block transform transition-all hover:scale-105 hover:shadow-xl"
              >
                <div className="bg-white rounded-xl overflow-hidden shadow-lg">
                  <img 
                    src={grant.image} 
                    alt={grant.title}
                    className="w-full h-64 object-cover"
                  />
                  <div className="p-6">
                    <h3 className="text-2xl font-bold text-green-600 mb-4">{grant.title}</h3>
                    <p className="text-gray-600 mb-6">{grant.description}</p>
                    <div className="inline-flex items-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-400 transition-colors">
                      Continue Reading
                      <ChevronRight className="ml-2 w-4 h-4" />
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GrantList;