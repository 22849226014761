import React, { useState, useEffect } from 'react';
import { Book, Calendar, User, ArrowRight, Loader, Image as ImageIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import config from '../config';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);  // Initialize as empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/blogs`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          const text = await response.text();
          console.error('Server response:', text);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        if (Array.isArray(data)) {
          setBlogs(data);
        } else {
          console.error('Unexpected data format:', data);
          throw new Error('Invalid data format received from server');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setError(error.message || 'Failed to fetch blogs. Please try again.');
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-green-50 via-green-100 to-green-50">
      <Loader className="w-16 h-16 text-green-600 animate-spin" />
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-green-50 via-green-100 to-green-50">
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg shadow-lg">
        <p className="font-bold">Error</p>
        <p>{error}</p>
      </div>
    </div>
  );

  return (
    <div className="pt-32 min-h-screen bg-gradient-to-br from-green-50 via-green-100 to-green-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Header Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-green-800 mb-4">
            Latest Blog Posts
          </h1>
          <div className="w-24 h-1 bg-green-600 mx-auto mb-4"></div>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Discover our latest articles about energy efficiency and home improvement
          </p>
        </div>



        {/* Blog Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog, index) => (
            <Link
              to={`/blog/${blog._id}`}
              key={blog._id}
              className="group bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl mb-8 block"
            >
              {/* Image Container */}
              <div className="relative h-56 overflow-hidden">
                {blog.featuredImage ? (
                  <img 
                    src={blog.featuredImage.startsWith('/') ? blog.featuredImage : `/${blog.featuredImage}`}
                    alt={blog.title}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                    <ImageIcon className="w-12 h-12 text-gray-400" />
                  </div>
                )}
                <div className="absolute top-4 left-4">
                  <span className="inline-block bg-green-600 text-white text-sm px-3 py-1 rounded-full">
                    Energy Efficiency
                  </span>
                </div>
              </div>

              {/* Content */}
              <div className="pt-6 pl-6 pr-6 ">
                {/* Title */}
                <h2 className="text-xl font-bold text-gray-800 mb-3 group-hover:text-green-600 transition-colors duration-300">
                  {blog.title}
                </h2>

                {/* Meta Information */}
                <div className="flex flex-wrap items-center text-sm text-gray-500 mb-4">
                  <div className="flex items-center mr-4">
                    <User className="w-4 h-4 mr-2" />
                    <span>{blog.author}</span>
                  </div>
                  <div className="flex items-center">
                    <Calendar className="w-4 h-4 mr-2" />
                    <span>
                      {new Date(blog.createdAt).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </span>
                  </div>
                </div>

                {/* Excerpt */}
                <p className="text-gray-600 mb-6 line-clamp-3">
                  {blog.content}
                </p>
                <div className="inline-flex items-center text-green-600 font-medium hover:text-green-700 transition-colors duration-300">
              Read More
              <ArrowRight className="w-4 h-4 ml-2 transition-transform duration-300 group-hover:translate-x-2" />
            </div>

              </div>
            </Link>
          ))}
                             {/* Static Blog Container */}
        <Link
          to="/grants/gbis"
          className="group bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl mb-8 block"
        >
          {/* Image Container */}
          <div className="relative h-56 overflow-hidden">
            <img
              src="/assets/gbis1.jpg"
              alt="Great British Insulation Scheme"
              className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
            />
          </div>

          {/* Content */}
          <div className="p-6">
            <h2 className="text-xl font-bold text-gray-800 mb-3 group-hover:text-green-600 transition-colors duration-300">
              Great British Insulation Scheme
            </h2>
            <p className="text-gray-600 mb-6">
            Various Grants are being provided to UK households under the Great British Insulation Scheme.
            </p>
            <div className="inline-flex items-center text-green-600 font-medium hover:text-green-700 transition-colors duration-300">
              Read More
              <ArrowRight className="w-4 h-4 ml-2 transition-transform duration-300 group-hover:translate-x-2" />
            </div>
          </div>
        </Link>
        </div>

        {/* Social Links */}
      </div>
    </div>
  );
};

export default BlogList;