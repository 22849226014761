import React, { useState, useEffect } from 'react';
import { Menu, X, Home, FileText, Gift, PhoneCall, FileQuestion } from 'lucide-react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { name: 'Home', icon: Home, link: '' },
    { name: 'Blog', icon: FileText, link: 'blogs'  },
    { name: 'Grants', icon: Gift, link: 'grants' },
    { name: 'FAQs', icon: FileQuestion, link: 'faq' },
    { name: 'Contact us', icon: PhoneCall, link: 'contact' },
  ];

  return (
    <header className={`fixed w-full z-40 transition-all duration-300 ${scrolled ? 'bg-white shadow-lg' : ''}`}>
      <nav className='bg-white shadow-lg'>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-32 md:h-32 lg:h-32">
            <div className="flex items-center">
              <Link to="/" className="flex items-center">
              <img 
  src="/assets/logo.png" 
  alt="EcoShine Solar Logo" 
  className="h-24 md:h-18 lg:h-24" // Increased from h-10 to h-12 on mobile, h-14 on tablet, and h-16 on desktop
/>
              </Link>
            </div>
            
            <div className="hidden md:flex items-center space-x-8">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={`/${item.link}`}
                  className="text-gray-700 hover:text-[#2c983f] transition-colors"
                >
                  {item.name}
                </Link>
              ))}
              <Link
                to="/check"
                className="bg-[#2c983f] text-white px-4 py-2 rounded-lg hover:bg-[#82cc2b] transition-colors"
              >
                Check Eligibility
              </Link>
            </div>

            <div className="md:hidden">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-gray-700 hover:text-[#2c983f] focus:outline-none"
              >
                {mobileMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        {mobileMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={`/${item.link}`}
                  className="text-gray-700 hover:text-[#2c983f] block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <div className="flex items-center">
                    <item.icon className="h-5 w-5 mr-2" />
                    {item.name}
                  </div>
                </Link>
              ))}
              <Link
                to="/check"
                className="bg-[#2c983f] text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-[#82cc2b] transition-colors"
                onClick={() => setMobileMenuOpen(false)}
              >
                Check Eligibility
              </Link>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;